label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 218px;
  /* height: 96px; */
  margin: 0 auto 10px;
  display: block;
  /* -moz-border-radius: 50%;
  -webkit-border-radius: 50%; */
  /* border-radius: 50%; */
}

.profile-img-card-header {
  width: 105px;
  height: 37px;
}

.loader-center {
  text-align: center;
  margin-top: 20px;
}

.MUIDataTableSelectCell-headerCell-41 {
  z-index: 1 !important;
}

.select-drops {
  max-width: 235px;
  /* height: 30px; */
}

.data-custom-table p {
  margin-bottom: 0px;
}

.data-custom-table {
  font-size: 14px;
}

.comm-rate-row,
.cost-price-row {
  width: 75px;
  margin-bottom: 10px;
}

.invoice-number,
.invoice-date {
  width: 105px;
}

.invoicebulkselect {
  width: 170px;
}

.payment-report-table tr td:nth-child(5),
.payment-report-table tr td:nth-child(6),
.payment-report-table tr td:nth-child(7),
.payment-report-table tr td:nth-child(8),
.payment-report-table tr td:nth-child(9) {
  background-color: #d4d4d4;
}

.payment-report-table tr td:nth-child(10),
.payment-report-table tr td:nth-child(11),
.payment-report-table tr td:nth-child(12),
.payment-report-table tr td:nth-child(13),
.payment-report-table tr td:nth-child(14),
.payment-report-table tr td:nth-child(15),
.payment-report-table tr td:nth-child(16),
.payment-report-table tr td:nth-child(17) {
  background-color: #bbb9f9;
}

.payment-report-table tr td:nth-child(18) {
  background-color: #f9d9b9;
  width: 30%;
}

.payment-report-table tr th:nth-child(18) {
  /* background-color: #f9d9b9; */
  width: 30%;
}

.payment-report-table tr td:nth-child(19) {
  background-color: #f6b9f9;
}

.payment-report-table tr td:nth-child(20) {
  background-color: #d4d4d4;
}

.bulk-actions {
  display: flex;
}

.stick-bulk {
  margin-top: 10px;
  position: fixed;
  top: 0;
  z-index: 999999;
  /* -webkit-border-radius: 0 0 10px 10px; */
  /* border-radius: 0 0 10px 10px; */
  background-color: white;
  padding: 20px 20px;
  border: 1px solid black;
  margin-left: 15px;
}

.background-grey {
  background-color: #c6c6c6 !important;
}

.background-green {
  background-color: #90fb73 !important;
}

tr:has(> span.background-green) {
  background-color: #90fb73 !important;
}

tr:has(> span.background-grey) {
  background-color: #c6c6c6 !important;
}

.heading-blue {
  background-color: #3c3cff;
  padding: 6px 15px;
  text-align: center;
  border-top: 2px solid #0f0952;
  font-weight: bold;
  color: white;
}

.subheading-blue {
  color: #6161ff;
  font-size: 20px;
  font-weight: bold;
}

.summary-container {
  display: flex;
  justify-content: space-between;
}

.summary-sub-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 11%;
}

.summary-sub-container:first-child {
  margin-left: 0px !important;
}

.summary-label {
  font-weight: bold;
  padding: 0px 10px;
  background-color: #bfbfbf;
}

.summary-data {
  padding: 10px;
  background-color: #e7dfdf;
}

.customer-summary-sidebar-container {
  padding: 10px;
  /* border: 1px solid black; */
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.summary-label-sidebar {
  font-weight: bold;
}

.link-primary {
  font-weight: bold;
  color: #1164bd;
}

.col-form-label {
  margin-top: 0;;
}
.supplierDetailsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px 20px;
    width: 90%;
    margin-bottom: 20px;
}

.KeyContactsContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap:10px 20px;
  width: 100%;
  margin-bottom: 20px;
}

.BankDetailsContainer {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-gap:10px 20px;
  width: 100%;
  margin-bottom: 20px;
}

.strike {
  position: relative;
  opacity: 0.6;
}

.strike td:before {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 2px solid #9d9898;
  width: 100%;
  z-index: 99999;
}

.hideit {
  display: none !important;
}

.product-detail-main-item {
  padding: 10px;
  background-color: #edecec;
  margin-bottom: 20px;
  border: 1px solid gray;
}

.receivables-view-products-container .prodname {
  background-color: #e9e9e9;
  font-size: 20px;
  padding: 6px 10px;
  border-bottom: 1px solid black;
  font-weight: bold;
}

.receivables-view-products-container .attributes {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 10px 20px;
}

.attr-label {
  font-weight: bold;
  background-color: beige;
  padding: 5px 8px;
  border-radius: 5px;
  margin-right: 20px;
}

.attr-item {
  display: flex;
  margin-bottom: 10px;
}

.receivable-table-type {
  font-size: 16px;
  font-weight: bold;
  color: green;
}

.receivable-table-type-porforma {
  font-size: 16px;
  font-weight: bold;
  color: red;
}

.receivable-table-due-date {
  font-weight: bold;
}

.receivables-table th{
  max-width: 255px;
}

.receivables-table td {
  text-align: center;
}

.receivables-table tr td:nth-child(1),
.receivables-table tr td:nth-child(4) {
  text-align: left !important;
}

.contract-table tbody tr td:nth-child(1),
.contract-table tbody tr td:nth-child(2) {
  background-color: #82de82;
}

.contract-table tbody tr td:nth-child(3),
.contract-table tbody tr td:nth-child(4),
.contract-table tbody tr td:nth-child(5) {
  background-color: #f7cfd6;
}

.contract-table tbody tr td:nth-child(6),
.contract-table tbody tr td:nth-child(7),
.contract-table tbody tr td:nth-child(8) {
  background-color: #bebef5;
}

.details-wrapper {
  display: flex;
  flex-direction: column;
}

.details-wrapper .product-wrapper {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f3f3f3;
}

.details-wrapper .product-wrapper .productname {
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1 px solid black;
}

.details-wrapper .product-wrapper .attribute-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.details-wrapper .product-wrapper .attribute-wrapper .data-wrapper {
  
  margin-bottom: 10px;
  margin-left: 10px;
}

.details-wrapper .product-wrapper .attribute-wrapper .data-wrapper .datalabel {

  font-weight: bold;
}

.show-total-fetched {
  color: red;
  margin-right: 20px;
  font-weight: bold;
}

.album-header-main {
      padding: 15px;
      font-size: 24px;
      font-weight: bold;
      color: white;
      border-bottom: 1px solid white;
      border-width: 100%;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
}

.album-header {
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  border: 1px solid #e7e5e5;
  border-width: 100%;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.album-header:hover{
  padding: 20px;
  font-size: 20px;
  background-color: #07747e;

}

.album-content {
    padding: 10px;
    background-color: #f8f8fb;
}

.album-content form label {
  font-size: 16px;
  font-weight: bold;
}

.album-content h4 {
  margin-bottom: 0;
  text-align: center;
  background: #aad9ff;
  padding: 15px;
  box-shadow: 0 1px 6px rgb(0 0 0 / 12%),
  0 1px 4px rgb(0 0 0 / 24%);
}

.green-back {
  background-color: greenyellow;
}

.steps-heading {
      text-align: center;
      margin-bottom: 15px;
      padding: 5px;
      background-color: #b0d6ff;
      font-size: 1rem;
      font-weight: 700;
      border: 1px solid gray;
}

.steps-progress {
  flex-direction: column;
  font-size: 1.1rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Orders reports */
.orders-h1 {
    font-size: 22px !important;
}
.filter-form-container{
  display:flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 20px;
}

.filter-form-checkbox {
  margin: 0 !important;
}

.filterform-label {
    margin-left: 10px;
    font-weight: 700;
    text-decoration: underline;
}

.payment-details-wrapper,
.refunds-details-wrapper {
  display: flex;
  flex-direction: column;
}

.payment-details-wrapper div {
  display: flex;
  flex-direction: column;
}

.payment-details-wrapper label {
  font-size: 18px;
  font-weight: bold;
}

.refunds-details-wrapper div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 7px;
}

.refunds-details-wrapper .label {
  font-size: 18px;
  font-weight: bold;
}

.filters-applied {
  background-color: #90fb73;
  color: white;
}

.filters-not-applied {
  background-color: #ebebeb;
  color: black;
}

.orderdetails-paper {
  width:100%;
  padding: 10px;
}

.orderdetails-paper h5 {
  font-size: 1.1rem;
  text-decoration: underline;
}

.orderdetails-paper-content-wraper {
  display: flex;
  flex-direction: column;
}

.orderdetails-paper-content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.orderdetails-paper-content-row label {
  font-weight: 500;
  margin:0;
}
.orderdetails-coupons-loop {
  padding: 10px;
  background-color: cornsilk;
  margin-bottom: 10px;
}

.orderdetails-items-table-wrapper {
  margin-top: 20px;;
}
.table-actions-button {
  text-align: center;
}
.table-actions-button .btn {
  margin-bottom: 6px;
}

.chip-yellow {
  background-color: #ffc107;
  color: black;
}

.chip-black {
  background-color: black;
  color: white;
}

.items-table td {
  text-align: center;
}

.order-totals-bar {
  padding: 10px;
  background-color: #fffdfd;
  margin-bottom: 20px;
  box-shadow: 10px 10px 42px -14px rgba(33, 13, 13, 0.75);
  -webkit-box-shadow: 10px 10px 42px -14px rgba(33, 13, 13, 0.75);
  -moz-box-shadow: 10px 10px 42px -14px rgba(33, 13, 13, 0.75);
  margin-left: 5px;
  margin-right: 5px;
}

.order-totals-bar .actions {
    display: flex;
    align-items: flex-end;
}

.order-totals-bar .btn {
  border: 1px solid blue;
  color: blue;
}

.order-totals-bar .btn:nth-child(2),
.order-totals-bar .btn:nth-child(3) {
  margin-left: 15px;
}

.order-totals-bar .totals {
  display: flex;
  flex-direction: column;
}

.order-totals-bar .totals p{
  display: flex;
  justify-content: space-between;
}

.add-reminder-row {
  margin-top: 50px;
  margin-bottom:10px;
  padding: 10px;
  background-color: #fffdfd;
  border: 1px solid #d1cccc;
}

.reminder-form .form-group:nth-child(2),
.reminder-form .form-group:nth-child(3) {
  margin-left: 20px;
}

.reminder-form {
  align-items: flex-start;
  margin-bottom: 20px;
}

.confirm-buttons-email {
  background-color: #953a72;
  border-color: #966884;
  padding: 10px;
  color: white;
  cursor: pointer;
  width: 40%;
}

.confirm-buttons-phone {
  background-color: #007cba;
  border-color: #055881;
  padding: 10px;
  color: white;
  cursor: pointer;
  width: 40%;
}

.supplier-confirm-phone-heading {
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    background-color: #007cba;
    text-align: center;
    margin-bottom: 10px;
}

.supplier-confirm-phone-sub-heading {
  text-align: center;
  margin-bottom: 20px;
}

.orderitem-payment-details p{
  font-size: 14px;
  margin-bottom: 5px;
}

.orderitem-payment-details .first {
  margin-top: 10px;
}

.orderreport-order-tags-wrapper p {
  margin-top: 3px;
  margin-bottom: 3px;;
}


.comment-container {
    padding: 10px;
    background-color: aliceblue;
    margin-bottom: 10px;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border: 1px solid #0000000f;
}

.choosimage-image {
  margin:10px;
}
.choosimage-image:hover {
  padding: 10px;
  margin:0px;
  background-color: #2a749f;
  cursor: pointer;
}

.choosimage-image-selected {
  padding: 10px !important;
  margin: 0px !important;
  background-color: #ff0000 !important;
  cursor: pointer !important;
}

.viewmoregiftcard {
  margin: 10px;
  text-align: center;
  vertical-align: middle;
  background-color: #222327;
  height: 140px;
  width: 140px;
  min-width: 150px;
  min-height: 150px;
  top: 50%;
  display: table-cell;
  font-size: 16px;
  font-weight: bold;
  color: white;
  line-height: 150px;
  cursor: pointer;
}

.steps-progress div {
    background-color: #1d9b09;
    padding: 8px 21px;
    color: white;
    margin-bottom: 10px;
    background-color: #1d9b09;
    padding: 8px 21px;
    color: white;
    margin-bottom: 10px;
    border-radius: 4%;
}

.expiry-report-history-wrapper {
  flex-direction: column;
}

.expiry-report-history-wrapper .task-log-wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #a7a7a7;
    padding-bottom: 3%;
    margin-bottom: 3%;
}

.expiry-report-history-wrapper .task-logger-wrapper {
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  color: #7c7979;
}

.expiry-report-history-wrapper .task-logger-wrapper .user {
  font-size: 16px;
  font-weight: 700;
  text-decoration: underline;
  color: black;
}

.expiry-report-history-wrapper .task-log-item {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
}

.expiry-report-history-wrapper .task-log-item .task-log-item-label{
    font-weight: 600;
    color: #646363;
    font-size: 15px;
}

.expiry-report-history-wrapper .task-log-item .task-log-item-value{
    font-weight: 400;  
    font-size: 15px;
}

.ReactModal__Overlay  {
  /* margin-top:30px; */
  overflow: scroll;
}

.popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.popup.show {
  display: block;
  z-index: 99999999;
}

.popup-content {
  background-color: #fefefe;
  margin: 3% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 800px;
  overflow: scroll;
  max-height: 90vh;
}

.popup .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.popup .close:hover,
.popup .close:focus {
  color: black;
  text-decoration: none;
}

.popup form {
  display: flex;
  flex-direction: column;
}

.popup .form-group {
  display: flex;
  justify-content: flex-start;
  text-align: left;
}

.popup label {
  margin-bottom: 8px;
  min-width: 200px;
  width: 200px;
}

.popup-content .form-group {
  display: flex;
  justify-content: flex-start;
  align-items: normal;
}

.popup input {
  padding: 8px;
  margin-bottom: 16px;
}

.popup button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}

.popup button:hover {
  background-color: #45a049;
}


/* corporate */

.corporateaccountname {
  color: blue;
  text-decoration: underline;
}

.corporate-new .dt-buttons, .corporate-new .dataTables_filter {
  display: none !important
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  padding: 3px !important;
}

.css-j204z7-MuiFormControlLabel-root {
  margin-bottom: 10px !important;
}

.css-1m9pwf3 {
  width: unset !important;
  height: unset !important;
}

.tooltipn {
  position: relative; /* making the .tooltip span a container for the tooltip text */
  border-bottom: 1px dashed #000; /* little indicater to indicate it's hoverable */
}

.tooltipn:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* move to right */
  left: 100%;
  margin-left: 15px; /* and add a small left margin */

  /* basic styles */
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  font-weight: 500 !important;

  display: none; /* hide by default */
}

.tooltipn:hover:before {
  display: block;
}

.tooltipn.right:before {
  /* reset defaults */
  right: initial;
  margin: initial;

  /* set new values */
  left: 100%;
  margin-left: 15px;
}

.tooltipn.left:before {
  /* reset defaults */
  left: initial;
  margin: initial;

  /* set new values */
  right: 100%;
  margin-right: 15px;
}

.tooltipn:after {
  content: '';
  position: absolute;

  /* position tooltip correctly */
  left: 100%;
  margin-left: -5px;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* the arrow */
  border: 10px solid #000;
  border-color: transparent black transparent transparent;

  display: none;
}
.tooltipn:hover:before,
.tooltipn:hover:after {
  display: block;
}

.btnactive {
  background-color: #007cba !important;
  border-color: #055881 !important;
  color: white !important;
  cursor: pointer;
}

#refunds-table hr {
  margin-top: 0px;
  margin-bottom: 0px;
}

.comment-item {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 17px;
  margin-bottom: 10px;
}

.comment-date {
  color: grey;
  font-style: italic;
  font-size: 12px;
}

.comment-text {
  font-size: 14px;
  color: black;
}

.MuiDataGrid-cellContent {
  overflow: unset !important;
  text-overflow: unset !important;
  white-space: normal !important;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.row-bg-follow-up {
  background-color: #ecda30 !important;
}

.row-bg-part-payment {
  background-color: #587fe9 !important;
}

.row-bg-issue {
  background-color: #ec6464 !important;
}

.row-bg-credit {
  background-color: #61e968 !important;
}

.row-bg-refund {
  background-color: #c564f1 !important;
}

.row-bg-Follow-up, .row-bg-pending {
  background-color: #ecda30 !important;
}

.row-bg-updating {
  background-color: #dafad7 !important;
}

.dark-border-table td,
.dark-border-table th {
  border: 1px solid #727070;
}