.header-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header-line input {
    width: 400px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    font-size: 16px;
}

.mb-10 {
    margin-bottom: 10px;
}

.custom-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-popup-content {
    width: 500px;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    position: relative;
}

.custom-popup .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.bg-red {
    background-color: #dc3545 !important;
    color: #fff;
}

