.detailsForm {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.section50 {
    width: 50%;
    padding: 10px;
}

.section33 {
    width: 33%;
    padding: 10px;
}

.section100 {
    width: 100%;
    padding: 10px;
}

.section67 {
    width: 67%;
    padding: 10px;
}

.displayFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.section90 {
    width: 90%;
    padding: 10px;
}

.bold {
    font-weight: bold;
}

.sidebar {
    background-color: #f1f1f1;
    border: 1px solid grey;
    width: 15%;
}

.sidebar .sidebar-item {
    padding: 10px;
    padding-right: 0px;
    color: black;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid black;
    font-weight: 500;
}

.sidebar .sidebar-item:hover {
    background-color: #e0e0e0;
    scale: 1.1;
    border: 1px solid black;
}

.sidebar .sidebar-item.selected {
    background-color: #e0e0e0;
    scale: 1.1;
    border: 1px solid black;
    margin-left: 4.4%;
}

.content {
    width: 85%;
    padding: 20px;
    padding-top: 10px;
    border: 1px solid grey;
}

.content .content-item {
    font-weight: 500;
}

.content .content-item-text {
    font-weight: 400;
}

.mb10 {
    margin-bottom: 10px;
}

.evenbackground {
    background-color: #f1f1f1;
}
.oddbackground {
    background-color: #ffffff;
}

.contentrow {
    padding: 10px 10px;
}

.price-content {
    display: flex;
    flex-direction: column;    
    /* justify-content: flex-start;
    align-items: center; */
    margin-top: 20px;
}

.price-content input {
    width: 20%;
    margin-bottom: 10px;
    margin-right: 20px;
}