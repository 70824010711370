body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=checkbox]
{
  /* Double-sized Checkboxes */
  -ms-transform: scale(2); /* IE */
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
  padding: 10px;
}

/* Might want to wrap a span around your checkbox text */
.checkboxtext
{
  /* Checkbox text */
  font-size: 110%;
  display: inline;
}

.formSwitcher {
  display: flex;
  border-bottom: 1px solid grey;
  margin-bottom: 20px;
}

.formSwitcherButtons {
  padding: 10px;
  border: 1px solid grey;
  cursor: pointer;
}

.formSwitcherButtonsSelected {
  /* box-shadow: inset 0 0 20px #3ef753; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
  rgba(0, 0, 0, 0.12) 0px -12px 30px,
  rgba(0, 0, 0, 0.12) 0px 4px 6px,
  rgba(0, 0, 0, 0.17) 0px 12px 13px,
  rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-color: #40a740;
  color: white;
  font-weight: bold;
  text-decoration: underline;
  font-size: 17px;
}

.ui-datepicker {
  z-index: 99999 !important;
  position: absolute !important;
}

.adjust-label {
    margin-left: 10px;
    margin-top: -2px;
}

.depts-buttons {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.tasks-table hr {
  margin-top: 2px;
  margin-bottom: 2px;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.modal-subheading {
  color: black;
  font-weight: bold;
  border-bottom: 1px solid black;
  margin-top: 4%;
}

.comments-popup-label {
    color: #635d5d;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
}

.task-type-rows {
  margin-bottom: 0px !important;
}

.task-type-rows-teams {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: baseline;
    font-weight: bold;
}

.task-type-rows-teams span {
  min-width: 50% !important;
  padding: 0.1rem;
}

.compulsory-input-star {
    font-weight: bold;
    color: red;
    margin-left: 2px;
}

.tasks-data-table .dataTables_filter {
      width: 50%;
      display: flex;
      justify-content: end;
      align-items: center;
}

.tasks-data-table .dataTables_filter select {
    margin-left:20px;
    width: 200px;
}

.bold-text {
  font-weight: bold;
}

.products-task-form-table td {
  text-align: center;
  border: 1px solid grey;
  padding: 10px;
}

.hideit {
  display: none;
}

.fixedHeader-floating {
  background-color: #333333;
  color: white !important;
}

.showtoast {
  display:block;
}

.hidetoast {
  display: none;
}

#products-supplier {
  width: 300px;
}